<template>
  <div class="panel"><slot /></div>
</template>

<style lang="scss" scoped>
.panel {
  background: $white;
  border-radius: 6px;
  box-shadow: $shadow;
  padding: rem(16);

  & + & {
    margin-top: rem(16);

    @include viewport('md') {
      margin-top: rem(24);
    }
  }
}
</style>
